.App {
    text-align: center;
    display: flex;
    flex-grow: 1;
    justify-content: center;
    height: 100%;
    background-color: #ffffff;
    overflow: auto;
}

/* TODO: Figure out if there is an more elegant way */
@media (min-width: 576px) {
    .App {
        background-color: #f5f5f5;
    }
}

.map {
    position: fixed;
    top: 0;
    left: -2501px;
    width: 2500px;
    height: 2500px;
}

/* Carousel custom css */
.carousel {
    overflow-x: scroll;
}
/* TODO: Fix scrollbar visibility. It should be visible when the scrollable area is hovered */
/* Hide scrollbar for Chrome, Safari and Opera */
.carousel::-webkit-scrollbar {
    display: none;
}
/* Hide scrollbar for IE, Edge and Firefox */
.carousel {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

/* Show carousel navigation buttons only on hover state */
.carousel-wrapper:not(:hover) button {
    display: none !important;
}

.carousel-wrapper button {
    box-shadow: 0px 1px 16px rgba(0, 0, 0, 0.2);
    width: 40px;
    height: 40px;
}
