$nav-tabs-border-color: transparent;
$nav-tabs-border-width: 0px;
$nav-tabs-link-hover-border-color: transparent;
$nav-tabs-link-active-border-color: transparent;
$nav-tabs-link-active-bg: $tractiq-100;
$nav-tabs-link-active-color: $tractiq;
$nav-tabs-link-hover-color: $black;
$nav-link-color: $black;

.selection-switcher {
    .full-width {
        width: 100% !important;
    }
    .nav-tabs {
        .nav-item {
            padding-left: 0;
            padding-right: 0;
            button {
                border: 1px solid #00000021;
                border-right: 0;
            }
            .nav-link:hover:not(.active) {
                color: $black !important;
                background-color: $backgroundSmoke;
            }
        }
        .nav-item:first-of-type {
            button {
                border-top-left-radius: 50rem !important;
                border-bottom-left-radius: 50rem !important;
                padding-left: 24px;
                div {
                    margin-right: 4px;
                    margin-left: -8px;
                }
            }
            .compare-multichip {
                border-top-left-radius: 8px !important;
                border-bottom-left-radius: 0 !important;
            }
        }
        .nav-item:last-of-type {
            button {
                border-top-right-radius: 50rem !important;
                border-bottom-right-radius: 50rem !important;
                padding-right: 24px;
                border-right: 1px solid #00000021;
            }
            div {
                margin-right: -8px;
                margin-left: 4px;
            }
            .compare-multichip {
                border-top-right-radius: 0 !important;
                border-bottom-right-radius: 8px !important;
            }
        }

        .nav-multichip-item {
            padding-left: 0;
            padding-right: 0;
            button {
                border: 1px solid #00000021;
                border-right: 0;
                font-size: 15px;
                font-weight: 600;
            }
            .nav-link:hover:not(.active) {
                color: $black !important;
                background-color: $backgroundSmoke;
            }
        }
        .nav-multichip-item:first-child {
            button {
                border-top-left-radius: 8px !important;
            }
            div {
                margin-right: 4px;
                margin-left: -8px;
            }
        }
        .nav-multichip-item:nth-child(2) {
            button {
                border-top-right-radius: 8px !important;
            }
        }
        .nav-multichip-item:nth-last-child(2) {
            button {
                border-bottom-left-radius: 8px !important;
            }
        }
        .nav-multichip-item:last-child {
            button {
                border-top-right-radius: 0 !important;
                border-bottom-right-radius: 8px !important;
                border-right: 1px solid #00000021;
            }
            div {
                margin-right: -8px;
                margin-left: 4px;
            }
        }
    }
}

.nav-tabs {
    .nav-item {
        padding-left: 0.4rem;
        padding-right: 0.4rem;
        .nav-link {
            border-radius: 10rem;
        }
        .nav-link:not(.active) {
            background: $black-100;
        }
        .nav-link:hover:not(.active) {
            color: $black;
        }
    }
}

.full-width {
    width: 100%;
    max-width: 350px;
}
.two-per-row {
    display: grid !important;
    grid-template-columns: repeat(2, 1fr);
}
.half-size {
    width: 100%;
}
