/*
    Redefine spacing. You can now use
    these values on bootstrap classes,
    e.g. className='mb-36' would set
    bottom margin to 36px (3.6rem). For padding,
    replace 'm' with 'p' (e.g. 'pb-36')
*/
$spacer: 0.1rem;
$spacers: (
    '0': (
        $spacer * 0,
    ),
    '1': (
        $spacer * 1,
    ),
    '2': (
        $spacer * 2,
    ),
    '4': (
        $spacer * 4,
    ),
    '5': (
        $spacer * 5,
    ),
    '6': (
        $spacer * 6,
    ),
    '8': (
        $spacer * 8,
    ),
    '10': (
        $spacer * 10,
    ),
    '12': (
        $spacer * 12,
    ),
    '14': (
        $spacer * 14,
    ),
    '16': (
        $spacer * 16,
    ),
    '20': (
        $spacer * 20,
    ),
    '24': (
        $spacer * 24,
    ),
    '25': (
        $spacer * 25,
    ),
    '28': (
        $spacer * 28,
    ),
    '30': (
        $spacer * 30,
    ),
    '32': (
        $spacer * 32,
    ),
    '34': (
        $spacer * 34,
    ),
    '36': (
        $spacer * 36,
    ),
    '40': (
        $spacer * 40,
    ),
    '46': (
        $spacer * 46,
    ),
    '48': (
        $spacer * 48,
    ),
    '60': (
        $spacer * 60,
    ),
    '72': (
        $spacer * 72,
    ),
);

