.popover {
    padding: 1.6rem;
    border-radius: 8px;
    box-shadow: 0px 1px 16px rgba($black, 0.2);
    border: none;
}

// We use this beacuse we cannot directly access popover style because it is automaticaly generated
// With this we pass this style trough popover parent #wide
#wide .popover {
    width: 380px;
    max-width: none;
}
