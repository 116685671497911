/*
    Introduces conditional borders to Bootstrap. You can
    now choose to hide borders on certain screen sizes.
    E.g. className='border-bottom border-sm-bottom-none'
    would display a bottom border, but hide it on screens
    that are small (sm)
*/
@each $breakpoint in map-keys($grid-breakpoints) {
    @include media-breakpoint-down($breakpoint) {
        .border-#{$breakpoint}-start-none {
            border-left: none !important;
        }
        .border-#{$breakpoint}-end-none {
            border-right: none !important;
        }
        .border-#{$breakpoint}-top-none {
            border-top: none !important;
        }
        .border-#{$breakpoint}-bottom-none {
            border-bottom: none !important;
        }
        .border-bottom-#{$breakpoint}-active{
            border-bottom: 1px solid #dee2e6 !important;
        }
        .border-top-#{$breakpoint}-active{
            border-top: 1px solid #dee2e6 !important;
        }
        .border-start-#{$breakpoint}-active{
            border-left: 1px solid #dee2e6 !important;
        }
        .border-end-#{$breakpoint}-active{
            border-right: 1px solid #dee2e6 !important;
        }
    }
}

.shadow-hover:hover {
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.2);
}

.shadow-4 {
    box-shadow: 0.4rem 0.4rem 2rem rgba($black, 0.2) !important;
}

.radius-16 {
    border-radius: 16px !important;
}

.shadow--standard:hover {
    background-color: $black-100;
}

.radius-03 {
    border-radius: 0.3rem;
}
