button {
    .material-icons {
        font-size: 20px;
    }
}

.btn {
    &--filter {
        background-color: #1257dd1f;
        border-radius: 100px;
        border: 1px solid #1257dd1f;
        padding: 4px 16px;
    }
    &--hover {
        background-color: transparent;
        border-radius: 100px;
    }
    &--gray {
        background-color: $black-100;
    }
    &--light-gray {
        background-color: rgba(0, 131, 143, 0.04);
    }
    &--hover:hover {
        border-radius: 100px;
        background-color: $black-100;
    }
    &--customize {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 72px;
        height: 38px;
        border: none;
        font-size: 14px;
        font-weight: 600;
        border-radius: 4px;
        padding: 10px 16px;
        &.reset {
            color: $black-500;
            background-color: inherit;
        }
        &.apply {
            background-color: $tractiq-800;
            color: white;
        }
        &.add {
            width: 30px;
            height: 30px;
            border-radius: 100px;
            padding: 4px;
            gap: 10px;
            border: 1px solid $black-300;
            background-color: inherit;
        }
        &.close {
            padding-right: 10px;
            width: 30px;
            height: 30px;
            background-color: inherit;
            font-size: 18px;
            color: $gray-600;
            align-self: flex-start;
        }
    }
}

.btn:focus {
    outline: none;
    box-shadow: none;
}

.btn:focus-visible {
    outline: none;
    box-shadow: 0 0 1px 1px rgba($primary, 0.5);
}

.on-hover:hover {
    background-color: rgba(18, 87, 221, 0.12) !important;
}
