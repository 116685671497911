.modal-dropdown:hover {
    border-left: 4px solid transparent;
    background: rgba(245, 245, 245, 1);
    border-radius: 4px;
}

.modal-dropdown {
    background: rgba(255,255,255,1);
    border-left: 4px solid transparent;
    border-radius: 4px;
    &--selected {
        border-left: 4px solid $tractiq !important;
        background: rgba(245, 245, 245, 1);
        border-radius: 4px;
    }
}

@media only screen and (min-width: 600px) {
    .modal-backdrop {
        background-color: rgba(0, 0, 0, 0.25) !important;
    }

    .modal {
        &--industry {
            // for some reason the modal does not remain centered if we remove min width and height
            min-width: 630px;
            min-height: 630px;
            width: 630px;
            height: 630px;
        }
    }

}

@media only screen and (max-width: 600px) {
    .modal-backdrop {
        background-color: transparent !important;
    }

    .modal {
        &--industry {
            max-width: 630px;
        }
    }

    .modal-dialog-filter {
        left: 10%;
    }
}

.modal-body {
    overflow-x: hidden;
}

.modal-small {
    max-width: 367px;
}

.modal-body-small {
    max-height: 284px;
    overflow-y: auto;
}

.shadow-modal {
    box-shadow: 4px 4px 20px 0px rgba(0, 0, 0, 0.2);
}
