.svg-loader {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    &__text {
        color: rgba(0, 0, 0, 0.38);
        font-size: 16px;
        line-height: 24px;
    }
    &__heading {
        color: $black;
        font-size: $h1-font-size;
        line-height: 27px;
        padding: 24px 0;
    }
    &__icon {
        fill: $tractiq;
    }
}

// Loading animation
@keyframes loading {
    0% {
        opacity: 1;
    }
    50% {
        opacity: 0.5;
    }
    100% {
        opacity: 1;
    }
}

.custom-animation {
    background: #f5f5f5;
    opacity: 1;
    animation: 2s loading ease-in-out infinite;
    animation-delay: 0.8s;
}

// Animated donut
.circle_animation {
    stroke-dasharray: 440;
    stroke-dashoffset: 440;
}

@for $i from 0 through 100 {
    .filled-in-#{$i} .circle_animation {
        animation: filled-in-#{$i} 1s ease-out forwards;
    }
    @keyframes filled-in-#{$i} {
        to {
            stroke-dashoffset: (
                440- ($i * 4.4)
            ); /* 440 = 0% => 220 = 50% etc */
        }
    }
}
