$font-size-base: 1.5rem;
$font-weight-bold: 600;

$paragraph-margin-bottom: 0rem;

$headings-line-height: 2.4rem;

$h1-font-size: 2.8rem !default;
$h2-font-size: 2.1rem !default;
$h3-font-size: 1.9rem !default;
$h4-font-size: 1.7rem !default;
$h5-font-size: 1.5rem !default;
$h6-font-size: 1.4rem !default;
$h7-font-size: 1.3rem !default;

$font-sizes: (
    1: $h1-font-size,
    2: $h2-font-size,
    3: $h3-font-size,
    4: $h4-font-size,
    5: $h5-font-size,
    6: $h6-font-size,
    7: $h7-font-size,
) !default;

$font-family-sans-serif: 'Source Sans Pro', sans-serif;

$badge-font-weight: 400;

// h1
h1 {
    line-height: 3.6rem !important;;
    font-weight: 700 !important;
    color: $black-900;
    font-style: normal;
    opacity: 0.87;
}

// h2
h2 {
    line-height: 2.8rem !important;;
    font-weight: 600 !important;
    color: $black-900;
    font-style: normal;
    opacity: 0.87;
}

// h3
h3 {
    line-height: 2.4rem !important;;
    font-weight: 600 !important;
    color: $black-900;
    font-style: normal;
    opacity: 0.87;
}
// h4
h4 {
    line-height: 2.4rem !important;;
    font-weight: 600 !important;
    color: $black-900;
    font-style: normal;
    opacity: 0.87;
}
// h5
h5 {
    line-height: 2.4rem !important;;
    font-weight: 600 !important;
    color: $black-900;
    font-style: normal;
    opacity: 0.87;
}
// h6
h6 {
    line-height: 2.2rem !important;;
    font-weight: 600 !important;
    color: $black-900;
    font-style: normal;
    opacity: 0.87;
}
// h7
// does not exist by default, this is
// just to keep in line with design assets
.h7 {
    font-size: 1.3rem !important;
    line-height: 2.2rem;
    font-weight: 600;
    color: $black-900;
    font-style: normal;
}

.body-1 {
    font-size: 1.5rem;
    font-weight: 400;
    line-height: 24px;
    opacity: 0.87;
}

.body-2 {
    font-size: 1.4rem;
    font-weight: 400;
    line-height: 22px;
    opacity: 0.87;
}

.body-3 {
    font-size: 1.3rem;
    font-weight: 400;
    line-height: 22px;
    opacity: 0.87;
}

.body-4 {
    font-size: 1.2rem;
    font-weight: 400;
    line-height: 18px;
    opacity: 0.87;
}

